/* src/CustomSlider.css */
.slider-container {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  padding: 25px;
  border-radius: 8px;


}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  padding: 10px;
  overflow: hidden;
}

.slide {
  flex: 1;
  transition: transform 0.5s ease, font-weight 0.5s ease;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.review-content {
  padding: 20px;
}

.slide.active {
  transform: scale(1.1);
  font-weight: bold;
  border: 3px solid #7FEF00;
}

.rating {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}

.dots {
  text-align: center;
  margin-top: 30px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}
